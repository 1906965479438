<template>
  <div class="">
    <navbar title="我的"></navbar>
    <div class="mine-box">
      <!-- 用户头像 -->
      <div class="default-img">
        <img :src="headImgUrl" alt="">
      </div>
      <!-- 用户昵称 -->
      <div class="nick-name">{{nickname}}</div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from '@/components/tabBar.vue'
import navbar from '@/components/navBar.vue'

export default {
  name: 'Home',
  components: {
    tabbar,navbar
  },
  data(){
    return{
      nickname:"游客",
      headImgUrl:""
    }
  },
  mounted(){
    this.getMessage()
  },
  methods:{
    getMessage(){
      if(localStorage.getItem("nickname")){
        this.nickname = localStorage.getItem("nickname")
      }

      if(localStorage.getItem("headImgUrl")){
        this.headImgUrl = localStorage.getItem("headImgUrl")
      }else{
        this.headImgUrl = "@/assets/img/default-img.png"
      }
      
    }
  }
}
</script>
<style>
.mine-box{
  position: relative;
  margin: 60px auto 0;
  width: 95%;
  height: 300px;
  background-image: url(../assets/img/persion-back.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.default-img img{
  position: absolute;
  background: #fff;
  width: 120px;
  height: 120px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  margin-top: 60px;
}

.nick-name{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  bottom: 57px;
}
</style>
